import React from "react";
import { Grid } from "semantic-ui-react";
import MainLayout from "../../../components/HOC/MainLayout/MainLayout";
import "./dashboard.scss";
import create_pluse from "../../../Assets/image/create_pluse.svg";
import graph from "../../../Assets/image/graph.png";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const userId = useSelector((state) => state.persist.user)
  return (
    <>
      <MainLayout>
        <Grid columns={3} divided>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={8} computer={5} className="mb-3">
              <div className="cards">
                <div className="bg_Colour">
                  <div className="texting">
                    <h2>18</h2>
                    <p>Winers Created</p>
                    {userId !== 4 && (
                      <Link to="/panel/edit-profile-page">
                        <h6>
                          <img src={create_pluse} alt={create_pluse} /> Create
                          Winery
                        </h6>
                      </Link>
                    )}
                  </div>
                  <div className="flow_chart">
                    <img src={graph} alt={graph} />
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={5} className="mb-3">
              <div className="cards">
                <div className="bg_Colour">
                  <div className="texting">
                    <h2>54</h2>
                    <p>Users Created</p>
                  </div>
                  <div className="flow_chart">
                    <img src={graph} alt={graph} />
                  </div>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={6} className="mb-3">
              <div className="cards">
                <div className="bg_Colour">
                  <div className="texting">
                    <h2>54</h2>
                    <p>Bottles Created</p>
                    {userId !== 4 && (

                      <Link to="/panel/bottles/create-bottle">
                        <h6>
                          <img src={create_pluse} alt={create_pluse} /> Add Bottle
                        </h6>
                      </Link>
                    )}
                  </div>
                  <div className="flow_chart">
                    <img src={graph} alt={graph} />
                  </div>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </MainLayout>
    </>
  )
}

export default Dashboard;
