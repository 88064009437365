import React from 'react'
import './LeftSideBlock.scss'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../Assets/image/Brand_Logo.png'
import logo_Icon from '../../Assets/image/Brand_Logo_m.png'
import m1 from '../../Assets/image/m1.svg'
import m2 from '../../Assets/image/m2.svg'
import m3 from '../../Assets/image/m3.svg'
import m4 from '../../Assets/image/m4.svg'
import m5 from '../../Assets/image/m5.svg'

import { NotificationWhiteIcon } from '../../Assets/image/svgimages/sVGImg'

const LeftSideBlock = ({ toggleSidebar }) => {
  const userId = useSelector((state) => state.persist.user)
  const totalUnread = useSelector((state) => state.notification?.pendingNotificationList)

  const pendingWineryReqCount = useSelector((state) => state.wineMakers?.pendingWineryCount)

  const wineryCount = useSelector((state) => state.wineMakers?.wineryTotalCount)

  return (
    <div className={`leftBar ${toggleSidebar ? '' : 'smallSidebar'}`}>
      <div className='left_logoBlock'>
        <Link
          to='/nfts'
          title='Overview'
          className={`main_Icon ${toggleSidebar ? '' : 'logo_icon'}`}
        >
          <img src={logo} alt='logo' />
        </Link>
        <Link
          to='/nfts'
          title='Overview'
          className={`sideLogo_icon ${toggleSidebar ? '' : 'smalllogo_icon'}`}
        >
          <img src={logo_Icon} alt='logo_Icon' />
        </Link>
      </div>

      <div className='sidebarNav'>
        <ul>
          {userId !== 4 && (
            <>
              <li>
                <NavLink to='/panel/Dashboard'>
                  <img src={m1} alt='dashboard' />
                  Overview
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/WineList'>
                  {' '}
                  <img src={m2} alt='Banner' />
                  Winery Details
                  {wineryCount > 0 && <span className='counter'>{wineryCount}</span>}
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/users'>
                  <img src={m3} alt='User' />
                  Users
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/bottles'>
                  <img src={m4} alt='winer_icon' />
                  Bottle Of Wine
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/winery-requests'>
                  <img src={m5} alt='winer_icon' />
                  Winery Requests
                  {pendingWineryReqCount > 0 && (
                    <span className='counter'>{pendingWineryReqCount}</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/Dropevents'>
                  <img src={m5} alt='winer_icon' />
                  Drop Events
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/ware-house'>
                  <img src={m5} alt='winer_icon' />
                  Warehouse
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/notifications'>
                  <NotificationWhiteIcon />
                  Notifications
                  <span className={totalUnread?.length > 0 ? 'counter' : ''}>
                    {totalUnread?.length > 0 ? totalUnread?.length : ''}
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/marketplace'>
                  <img src={m4} alt='winer_icon' />
                  Marketplace
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/discount-codes'>
                  <img src={m4} alt='winer_icon' />
                  Discount Codes
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/drop-collections'>
                  <img src={m4} alt='winer_icon' />
                  Drop Collections
                </NavLink>
              </li>
              <li>
                <NavLink to='/panel/ela-consent/all'>
                  <img src={m4} alt='winer_icon' />
                  ELA Consents
                </NavLink>
              </li>
            </>
          )}
          <li>
            <NavLink to='/panel/kpis'>
              <img src={m4} alt='winer_icon' />
              KPIs
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default withRouter(LeftSideBlock)
