import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { ELAConsentService } from 'Services/elaConsent.service'
/** seting action types */
export const actionTypes = {
  SAVE_ALL_ELA_CONSENTS: 'SAVE_ALL_ELA_CONSENTS',
}

export function saveELAConsents(data) {
  return {
    type: actionTypes.SAVE_ALL_ELA_CONSENTS,
    payload: data,
  }
}

export function getAllELAConsents(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      ELAConsentService.getAllELAConsent(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveELAConsents(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
