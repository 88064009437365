import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { KPIService } from 'Services/kpi.service'
/** seting action types */
export const actionTypes = {
  SAVE_KPIs: 'SAVE_KPIs',
}

export function saveKPIs(data) {
  console.log(data)
  return {
    type: actionTypes.SAVE_KPIs,
    payload: data,
  }
}

export function getKPIs() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      KPIService.getKPIFiles({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveKPIs(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function generateKPIs() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      KPIService.generateKPIFile({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveKPIs(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}