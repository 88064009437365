import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getRevenueFiles = (headers) => {
    console.log(headers)
    return fetch('get', `${API_HOST}/admin/api/v1/statistics/serveRevenueKPIs`, {}, headers)
}

const generateRevenueFiles = (headers) => {
    console.log(headers)
    return fetch('get', `${API_HOST}/admin/api/v1/statistics/updateRevenueKPIs?type=MANUAL`, {}, headers)
}

export const RevenueFilesService = {
    getRevenueFiles,
    generateRevenueFiles,
}