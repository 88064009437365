import { useEffect } from 'react'
import { Route, useLocation } from 'react-router-dom'

import { withRouter } from 'react-router'
import { connect, useDispatch, useSelector } from 'react-redux'

import Dashboard from '../../pages/private/dashboard/Dashboard'
import WineList from '../../pages/private/WineMakers /WineList'
import UserList from '../../pages/private/user/user'
import BottleCreate from '../../pages/private/bottles/CreateBottle/Createbottle'
import WineryRequest from '../../pages/private/WineryRequest/WineryRequest'
import Notifications from '../../pages/private/Notifications/Notifications.jsx'
import WinemakerDetails from '../../pages/private/WinemakerDetails/WinemakerDetails'
import CreateWinemakers from '../../pages/private/CreateWinemakers/CreateWinemakers'
import EditProfilePage from '../../pages/private/Profile/EditProfilePage/EditProfilePage'
import ExclusiveAccess from '../../pages/private/ExclusiveAccess/ExclusiveAccess'
import CreatePublicPage from '../../pages/private/Public/CreatePublicPage/CreatePublicPage'
import WineMaker from '../../pages/private/Public/winemaker1/WineMaker'
import AddWinemaker from '../../pages/private/winemakers/addWinemaker/AddWinemaker'
import CreateWineryRequest from '../../pages/private/CreateWineryRequest/CreateWineryRequest'

// -----------------------------------------------------

////////////////////////////////////////////////////////////////////////////////////////
import EditBottle from '../../pages/private/bottles/EditBottle/EditBottle'
import LabelPage from '../../pages/private/Label/LabelPage'
import CreateNftWine from '../../pages/private/WineMakers /CreateNFT/CreateNftWine.jsx'
import { saveBreadCrum } from '../../redux/_actions/breadCrum.action'
import EditWineft from '../../pages/private/WineMakers /EditWineft/EditWineft.jsx'
import EditWineft_Second from '../../pages/private/WineMakers /EditWineft/EditWineft_Second'
import DropEvents from '../../pages/private/Drop_Events/DropEvents'
import DraftNft from '../../pages/private/PreviewWiNeFT/DraftNft'
import ListedNft from '../../pages/private/PreviewWiNeFT/ListedNft'
import LiveNft from '../../pages/private/PreviewWiNeFT/LiveNft'
import MarketPlaceNft from '../../pages/private/PreviewWiNeFT/MarketPlaceNft'
import Marketplace from '../../pages/private/Marketplace/Marketplace'
import WareHouse from '../../pages/private/wareHouse/WareHouse'
import WareNftId from '../../pages/private/wareHouse/wareNftId/WareNftId'
import Pallet from '../../pages/private/wareHouse/palletHouse/Pallet'
import PalletNftId from '../../pages/private/wareHouse/palletNftId/PalletNftId'
import TransferNFT from '../../pages/private/transferNFT/TransferNFT'

import DiscountCodes from 'pages/private/discountCodes/list/DiscountCodes'
import DiscountCodeCreate from 'pages/private/discountCodes/details/DiscountCodeCreate'
import DiscountCodeEdit from 'pages/private/discountCodes/details/DiscountCodeEdit'
import DropCollections from 'pages/private/dropCollections/list/DropCollections'
import DropCollectionCreate from 'pages/private/dropCollections/details/DropCollectionCreate'
import DropCollectionEdit from 'pages/private/dropCollections/details/DropCollectionEdit'
import ELAConsents from 'pages/private/elaConsents/list/ELAConsents'
import KPIPage from 'pages/private/kpis/KPIPage'


const PrivateRoutes = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const breadCrumPage = useSelector((state) => state.breadCrum.bredCrum)

  useEffect(() => {
    switch (location.pathname) {
      case '/panel/Dashboard': {
        dispatch(saveBreadCrum('dashboard'))
        break
      }
      case '/panel/WineList':
      case '/panel/add-winemaker':
      case '/panel/edit-profile-page':
      case '/panel/bottles/create-bottle': {
        if (
          (breadCrumPage === 'winelist' && location.pathname === '/panel/bottles/create-bottle') ||
          location.pathname !== '/panel/bottles/create-bottle'
        ) {
          dispatch(saveBreadCrum('winelist'))
        }
        break
      }
      case '/panel/users': {
        dispatch(saveBreadCrum('users'))
        break
      }
      case '/panel/bottles': {
        dispatch(saveBreadCrum('bottles'))
        break
      }

      case '/panel/winery-requests': {
        dispatch(saveBreadCrum('wineryRequest'))
        break
      }

      case '/panel/notifications': {
        dispatch(saveBreadCrum('notifications'))
        break
      }
      case '/panel/Dropevents': {
        dispatch(saveBreadCrum('dropEvents'))
        break
      }
      case '/panel/ware-house': {
        dispatch(saveBreadCrum('ware-house'))
        break
      }
      case '/panel/Warenft/:id': {
        dispatch(saveBreadCrum('wareNftId'))
        break
      }
      case '/panel/Pallet/': {
        dispatch(saveBreadCrum('pallet'))
        break
      }
      case '/panel/Pallet/:id': {
        dispatch(saveBreadCrum('palletNftId'))
        break
      }
      case '/panel/marketplace': {
        dispatch(saveBreadCrum('marketplace'))
        break
      }

      case '/panel/discount-codes':
      case '/panel/discount-codes/create':
      case '/panel/discount-codes/edit/:id': {
        dispatch(saveBreadCrum('discount-codes'))
        break
      }
      case '/panel/drop-collections':
      case '/panel/drop-collections/create':
      case '/panel/drop-collections/edit/:id': {
        dispatch(saveBreadCrum('drop-collections'))
        break
      }
      case '/panel/ela-consent/all': {
        dispatch(saveBreadCrum('ela-consent'))
        break
      }
      case '/panel/kpis': {
        dispatch(saveBreadCrum('kpis'))
        break
      }
      default:
    }
  }, [location])

  return (
    <div className='PrivateArea__content'>
      <Route path='/panel/Dashboard' component={Dashboard} exact={true} />
      <Route path='/panel/WineList' component={WineList} exact={true} />
      <Route path='/panel/users' component={UserList} exact={true} />
      <Route path='/panel/bottles/create-bottle' component={BottleCreate} />
      <Route path='/panel/winery-requests' component={WineryRequest} exact={true} />
      <Route path='/panel/notifications' component={Notifications} exact={true} />
      <Route path='/panel/winemaker-detail' component={WinemakerDetails} exact={true} />
      <Route path='/panel/CreateWinemakers' component={CreateWinemakers} exact={true} />
      <Route path='/panel/edit-profile-page' component={EditProfilePage} exact={true} />
      <Route path='/panel/exclusive-access' component={ExclusiveAccess} exact={true} />
      <Route path='/panel/view-public-page' component={CreatePublicPage} exact={true} />
      <Route path='/panel/public-page-prev' component={WineMaker} exact={true} />
      <Route path='/panel/add-winemaker' component={AddWinemaker} exact={true} />
      <Route path='/panel/CreateWinemakersRequest' component={CreateWineryRequest} exact={true} />
      <Route path='/panel/bottles/edit-bottle' component={EditBottle} />
      <Route path='/panel/edit-wine-maker' component={CreateWinemakers} exact={true} />
      <Route path='/panel/bottles' component={LabelPage} exact={true} />
      <Route path='/panel/createNftwine' component={CreateNftWine} exact={true} />
      <Route path='/panel/EditWineft' component={EditWineft} exact={true} />
      <Route path='/panel/EditWineft_Second' component={EditWineft_Second} exact={true} />
      <Route path='/panel/ware-house' component={WareHouse} exact={true} />
      <Route path='/panel/Warenft/:id' component={WareNftId} exact={true} />
      <Route path='/panel/Pallet/:id' component={Pallet} exact={true} />
      <Route path='/panel/PalletNftId/:id' component={PalletNftId} exact={true} />
      <Route path='/panel/Dropevents' component={DropEvents} exact={true} />
      <Route path='/panel/DraftNft' component={DraftNft} exact={true} />
      <Route path='/panel/ListedNft' component={ListedNft} exact={true} />
      <Route path='/panel/LiveNft' component={LiveNft} exact={true} />
      <Route path='/panel/MarketPlaceNft' component={MarketPlaceNft} exact={true} />
      <Route path='/panel/marketplace' component={Marketplace} exact={true} />
      <Route path='/panel/TransferNFT' component={TransferNFT} exact={true} />
      <Route path='/panel/discount-codes' component={DiscountCodes} exact={true} />
      <Route path='/panel/discount-codes/create' component={DiscountCodeCreate} exact={true} />
      <Route path='/panel/discount-codes/edit/:id' component={DiscountCodeEdit} exact={true} />
      <Route path='/panel/drop-collections' component={DropCollections} exact={true} />
      <Route path='/panel/drop-collections/create' component={DropCollectionCreate} exact={true} />
      <Route path='/panel/drop-collections/edit/:id' component={DropCollectionEdit} exact={true} />
      <Route path='/panel/ela-consent/all' component={ELAConsents} exact={true} />
      <Route path='/panel/kpis' component={KPIPage} exact={true} />
    </div>
  )
}
const mapStateToProps = (state) => {
  return { loggedIn: state.persist.loggedIn }
}

export default connect(mapStateToProps, null)(withRouter(PrivateRoutes))
