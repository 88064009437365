import { startLoading, stopLoading } from './loading.action'
// import { toast } from '../../components/Toast/Toast'
import { RevenueFilesService } from 'Services/revenueFiles.service'
/** seting action types */
export const actionTypes = {
  SAVE_REVENUE_FILES: 'SAVE_REVENUE_FILES',
}

export function saveRevenueFiles(data) {
  return {
    type: actionTypes.SAVE_REVENUE_FILES,
    payload: data,
  }
}

export function getRevenueFiles() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      RevenueFilesService.getRevenueFiles({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveRevenueFiles(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function generateRevenueFiles() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      RevenueFilesService.generateRevenueFiles({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveRevenueFiles(res.data?.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}