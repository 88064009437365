import React, { useState } from "react";
import "./login.scss";
import {
  Container,
  Form,
  Header,
  Image,
  Icon,
} from "semantic-ui-react";
import { withRouter } from "react-router";
import { Field, reduxForm } from "redux-form";
import { FormField } from "../../../components/FormField";
import { required } from "redux-form-validators";
import { UserActions } from "../../../redux/_actions";
import { useDispatch } from "react-redux";
import logo from "../../../Assets/image/Brand_Logo.png";
import { Link } from "react-router-dom";

const Login = ({ handleSubmit }) => {
  const dispatch = useDispatch();

  const submitForm = async (data) => {
    const { login } = UserActions;
    await dispatch(login(data));
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Container fluid className="loginContainer">
      <Container className="main_loginForm">
        <div className="loginForm">
          <div className="loginformBox">
            <Image src={logo} className="logoStyle" />
            <Header as="h2" content="Login" className="regTitle" />

            <Form
              autoComplete="off"
              autoFocus="off"
              onSubmit={handleSubmit(submitForm)}
            >
              <Form.Field>
                <label>Email</label>
                <Field
                  component={FormField}
                  name="username"
                  type="text"
                  placeholder="Username"
                  validate={[required()]}
                  autocomplete="off"
                />
              </Form.Field>

              <Form.Field className="edit_Icon">
                <label>Password</label>

                <Field
                  className="edit_Icon"
                  component={FormField}
                  // type="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  validate={[required()]}
                  autoComplete="off"
                />
                {showPassword ? (
                  <Icon
                    name="eye"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <Icon
                    name="eye slash"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </Form.Field>
              <Form.Field className="loginBtn">
                <button type="submit" primary="true">
                  {" "}
                  Submit{" "}
                </button>
              </Form.Field>
              <Form.Field className="loginBtn">
                <Link to="/reset">Forget Password</Link>
              </Form.Field>
            </Form>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default withRouter(
  reduxForm({ form: "LoginForm", enableReinitialize: true })(Login)
);
