import { actionTypes } from '../_actions/elaConsents.action'

const initialState = {
  entries: [],
  total: 0,
}

const elaConsent = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_ALL_ELA_CONSENTS:
      return { ...state, entries: payload.rows, total: payload.count }

    default:
      return state
  }
}

export default elaConsent
