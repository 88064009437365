import { useState } from 'react'
import { UploadOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useDispatch } from 'react-redux'

import { ButtonCustom } from 'components/button/ButtonCustom'
import { toast } from 'components/Toast/Toast'

import comCSS from './ActionsBulk.module.scss'
import { Plusicon } from 'Assets/image/svgImg'

const ActionsBulkUpdate = ({ actionFn = () => { }, content = {}, isMultiple = false }) => {
  const dispatch = useDispatch()
  const [isCreateBulk, setIsCreateBulk] = useState(false)
  const [fileList, setFileList] = useState([])
  const [uploading, setUploading] = useState(false)
  const [resFileUrl, setResFileUrl] = useState(null)

  const { trigger, body } = content || {}
  const triggerText = trigger?.text || 'Update Bulk'
  const bodyTitle = body?.title || 'Bulk Update'

  const handleUpload = () => {
    if (fileList.length === 0) return
    const formData = new FormData()
    const files = isMultiple ? fileList : fileList.slice(0, 1)
    files.forEach((f) => {
      formData.append('file', f)
    })

    setUploading(true)
    setResFileUrl(null)
    dispatch(actionFn(formData))
      .then((res) => {
        const data = res.data?.data
        if (data?.error) {
          toast.error('Failed to update bulk.')
        } else if (res?.data?.error) {
          toast.error('Failed to update bulk.')
        } else {
          toast.success('Successfully updated')
        }
        setResFileUrl(data?.fileUrl)
      })
      .catch(() => {
        toast.error('upload failed.')
      })
      .finally(() => {
        setFileList([])
        setUploading(false)
      })
  }

  const props = {
    onRemove: (file) => {
      setFileList((arr) => arr.filter((el) => el !== file))
    },
    beforeUpload: (file) => {
      const isXLSX = [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
        'application/vnd.ms-excel.sheet.macroEnabled.12',
      ].some((el) => el === file.type)
      if (!isXLSX && !isMultiple) {
        toast.error(`${file.name} is not a xlsx file`)
        return null
      }
      setResFileUrl(null)
      if (isMultiple) {
        setFileList((state) => [...state, file])
      } else {
        setFileList([file])
      }
      return false
    },
    fileList,
    className: cn(comCSS.uploadFileBtnWrapper),
    ...(isMultiple && { multiple: true }),
  }
  return (
    <div className={cn(comCSS.wrapper)}>
      <div className={cn(comCSS.btnWrapper)}>
        <ButtonCustom
          onClick={() => setIsCreateBulk(true)}
          title={triggerText}
          leftIcon={<Plusicon />}
        />
      </div>
      {isCreateBulk && (
        <div className={cn(comCSS.uploadWrapper)}>
          <h3>{bodyTitle}</h3>
          <div className={cn(comCSS.btnsWrapper)}>
            <Upload {...props}>
              <ButtonCustom
                title='Select File'
                customClass={cn(comCSS.uploadFileBtn)}
                leftIcon={<UploadOutlined />}
              />
            </Upload>

            <ButtonCustom
              onClick={handleUpload}
              disabled={fileList.length === 0}
              loading={uploading}
              customClass={cn(comCSS.submitBtn)}
              title={uploading ? 'Uploading...' : 'Upload'}
            />
          </div>
          {resFileUrl && (
            <div className={cn(comCSS.resultWrapper)}>
              <a
                href={resFileUrl}
                target='_blank'
                rel='noreferrer'
                className={cn(comCSS.resultLink)}
              >
                <strong>Check Results</strong>
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

ActionsBulkUpdate.propTypes = {
  actionFn: PropTypes.func.isRequired,
  content: PropTypes.shape({
    trigger: PropTypes.shape({
      text: PropTypes.string,
    }),
  }),
}

export default ActionsBulkUpdate
