import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getKPIFiles = (headers) => {
    console.log(headers)
    return fetch('get', `${API_HOST}/admin/api/v1/statistics/serveMainKPIs`, {}, headers)
}

const generateKPIFile = (headers) => {
    console.log(headers)
    return fetch('get', `${API_HOST}/admin/api/v1/statistics/updateMainKPIs?type=MANUAL`, {}, headers)
}

export const KPIService = {
    getKPIFiles,
    generateKPIFile,
}