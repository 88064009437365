import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Icon, Input, Dropdown } from 'semantic-ui-react'

import '../../../WinemakerDetails/WinemakerDetails.scss'

const sortByOption = [
  { key: '1', text: 'Newest', value: 'desc' },
  { key: '2', text: 'Oldest', value: 'asc' },
]

const optionLimit = [
  { key: '1', text: 10, value: '10' },
  { key: '2', text: 15, value: '15' },
  { key: '3', text: 20, value: '20' },
]

const Filters = ({
  search,
  setSearch,
  status,
  setStatus,
  order,
  setOrder,
  limit,
  setLimit,
  offset,
  setOffset,
}) => {
  const totalDiscounts = useSelector((state) => state?.elaConsent?.total)

  return (
    <div className='filltersUser'>
      <div className='filltersBottom'>
        <div className='left_fill'>
          <div className='sorting'>
            <span>Sort by</span>

            <Dropdown
              defaultValue='desc'
              placeholder='Oldest'
              selection
              options={sortByOption}
              onChange={(e, data) => {
                if (data?.value === 'desc') {
                  setOrder('')
                } else {
                  setOrder(data?.value)
                }
              }}
            />
          </div>
          <Input
            className='search_fld'
            icon={<Icon name='search' inverted link />}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            placeholder='Ex. Search by code'
          />
        </div>
        <div className='rightfill'>
          <div className='sorting'>
            <span>Show</span>
            <Dropdown
              value={limit.toString()}
              selection
              options={optionLimit}
              className='autowidth'
              onChange={(e, data) => {
                setLimit(data.value)
              }}
            />
          </div>
          <div className='sorting'>
            <span>Entries</span>
            <Dropdown
              placeholder={totalDiscounts ? totalDiscounts.toString() : '0'}
              selection
              options={optionLimit}
              className='autowidth'
              disabled
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Filters.defaultProps = {
  search: '',
  status: '',
  order: 0,
  limit: 10,
  offset: 1,
}
Filters.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  status: PropTypes.string,
  setStatus: PropTypes.func.isRequired,
  order: PropTypes.string,
  setOrder: PropTypes.func.isRequired,
  limit: PropTypes.number,
  setLimit: PropTypes.func.isRequired,
  offset: PropTypes.number,
  setOffset: PropTypes.func.isRequired,
}

export default Filters
