import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Table } from 'semantic-ui-react'

import '../../WinemakerDetails/WinemakerDetails.scss'

const KPIsTable = () => {
  const { entries } = useSelector((state) => state?.kpi)
  if (!entries?.length) return null

  return (
    <>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='1'>KPI Type</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Creation Type</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Download</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entries?.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {item?.kpi_type || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {item?.creation_type || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {item.date || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  <a href={item?.url}>Download</a>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}

KPIsTable.defaultProps = {
  limit: 10,
}
KPIsTable.propTypes = {
  limit: PropTypes.number,
  setLimit: PropTypes.func.isRequired,
  getAllDiscounts: PropTypes.func.isRequired,
}

export default KPIsTable
