import { Tabs } from 'antd'
import MainLayout from '../../../components/HOC/MainLayout/MainLayout'
import KPIsTable from './kpisTable/KPIsTable'
import { generateKPIs, getKPIs } from 'redux/_actions/kpi.action'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import '../wareHouse/WareHouse.scss'
import { ButtonCustom } from 'components/button/ButtonCustom'
import cn from 'classnames'
import comCSS from './KPIPage.module.scss'
import RevenueTable from './revenueTable/RevenueTable'
import { generateRevenueFiles, getRevenueFiles } from 'redux/_actions/revenueFiles.action'
const { TabPane } = Tabs

const KPIPage = () => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState('')


  useEffect(() => {
    dispatch(getKPIs())
    dispatch(getRevenueFiles())
    if (localStorage.getItem('revenue')) {
      setSelectedTab(localStorage.getItem('revenue'))
    } else {
      localStorage.setItem('activeTab', 'kpi')
      setSelectedTab('kpi')
    }
  }, [])
  const handleGenerateKPI = async () => {
    selectedTab === 'kpi' ? dispatch(generateKPIs()) : dispatch(generateRevenueFiles())
    // dispatch(generateKPIs())
  }

  const handleDownload = async (type) => {
    const url = type === 'main' ? process.env.REACT_APP_MAIN_KPI : type === 'drops' ? process.env.REACT_APP_DROP_KPI : process.env.REACT_APP_SETUP_KPI
    const a = document.createElement('a')
    a.href = url
    a.target = '_blank'
    a.style.display = 'none' // Hide the link element
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <MainLayout>
      <h3 className={cn(comCSS.commonSubHeading)}>KPIs</h3>
      <div className={cn(comCSS.btnWrapper)}>
        <ButtonCustom
          onClick={handleGenerateKPI}
          title={selectedTab === 'kpi' ? 'Generate KPI Data File' : 'Generate Revenue Data File'}
        // leftIcon={<Plusicon />}
        />
        {selectedTab === 'kpi' && (
          <>
            <ButtonCustom
              onClick={() => handleDownload('main')}
              title='Download Main KPI File'
            // leftIcon={<Plusicon />}
            />
            <ButtonCustom
              onClick={() => handleDownload('drops')}
              title='Download Drops KPI File'
            // leftIcon={<Plusicon />}
            />
            <ButtonCustom
              onClick={() => handleDownload('pdf')}
              title='Download Setup PDF'
            // leftIcon={<Plusicon />}
            />
          </>
        )}
      </div>
      <Tabs
        className='wareContainer_tabs__tabOver'
        defaultActiveKey='1'
        activeKey={selectedTab}
        onChange={(e) => {
          localStorage.setItem('activeTab', e)
          setSelectedTab(e)
        }}
      >
        <TabPane tab='Main KPIs Data' key='kpi'>
          <KPIsTable />
        </TabPane>
        <TabPane tab='Revenue Reconciliation' key='revenue'>
          <RevenueTable />
        </TabPane>
      </Tabs>
    </MainLayout >
  )
}

export default KPIPage
