import moment from 'moment'
import React from 'react'
import { Icon, Input, Dropdown, Table, Pagination } from 'semantic-ui-react'
import DropdownEvents from '../../../components/common/DropdownEvents/DropdownEvents'
import '../WinemakerDetails/WinemakerDetails.scss'
import { useHistory } from 'react-router'
import DropsTableWineName from './dropsTableWineName/DropsTableWineName'

const sortByOption = [
  { key: '1', text: 'Newest', value: 'desc' },
  { key: '2', text: 'Oldest', value: 'asc' },
]

const option2 = [
  { key: '1', text: 'Last Updated', value: '0' },
  { key: '2', text: 'Last Week', value: '1' },
  { key: '3', text: 'Last Month', value: '2' },
]
const optionLimit = [
  { key: '1', text: 10, value: '10' },
  { key: '2', text: 15, value: '15' },
  { key: '3', text: 20, value: '20' },
]

const DraftsTab = ({
  setStatus,
  setSearch,
  search,
  limit,
  setOffset,
  setLimit,
  setOrder,
  nftList,
  totalNft,
  selectedTab,
  getAllNft,
}) => {
  const history = useHistory()
  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }

  const formatAMPM = (date) => {
    let hours = date.getHours()
    let minutes = date.getMinutes()

    minutes = minutes < 10 ? `0${minutes}` : minutes

    const strTime = `${hours}:${minutes}`
    let dates = moment(date).format('ddd DD-MMM-YYYY')

    return dates + ' ' + strTime
  }

  const handleTableRowClick = (item) => {
    history.push({
      pathname: '/panel/EditWineft',
      state: item,
    })
  }

  return (
    <div className='detail-tab'>
      <div className='filltersUser'>
        <div className='filltersBottom'>
          <div className='left_fill'>
            <Dropdown
              placeholder='Last Updated'
              selection
              options={option2}
              onChange={(e, data) => {
                if (data?.value === '0') {
                  setStatus('')
                } else {
                  setStatus(data?.value)
                }
              }}
            />

            <div className='sorting'>
              <span>Sort by</span>
              <Dropdown
                placeholder='Oldest'
                selection
                options={sortByOption}
                onChange={(e, data) => {
                  if (data?.value === 'desc') {
                    setOrder('')
                  } else {
                    setOrder(data?.value)
                  }
                }}
              />
            </div>
            <Input
              className='search_fld'
              icon={<Icon name='search' inverted link />}
              placeholder='Ex. Search Drop Id or Winery name'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <div className='rightfill'>
            <div className='sorting'>
              <span>Show</span>
              <Dropdown
                value={limit.toString()}
                selection
                options={optionLimit}
                className='autowidth'
                onChange={(e, data) => {
                  setLimit(data.value)
                }}
              />
            </div>
            <div className='sorting'>
              <span>Entries</span>
              <Dropdown
                placeholder={totalNft ? totalNft.toString() : '0'}
                selection
                options={optionLimit}
                className='autowidth'
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2' className='SpacingList'>
                Drop ID
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Drop Name</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Start Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='2' className='SpacingList'>
                Duration
              </Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Status</Table.HeaderCell>
              <Table.HeaderCell colSpan='4'>No. of NFTs listed</Table.HeaderCell>
              <Table.HeaderCell colSpan='4'>No. of NFTs sold</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Winery</Table.HeaderCell>

              <Table.HeaderCell colSpan='2' className='SpacingList'>
                NFT Price
              </Table.HeaderCell>

              <Table.HeaderCell colSpan='2'>Exclusive</Table.HeaderCell>
              <Table.HeaderCell colSpan='1' className='kebebMenu'></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {selectedTab === 'DRAFT' &&
              nftList.map(
                (item) =>
                  (item.status === 'DRAFT' || item.status === 'EMPTY_DRAFT') && (
                    <Table.Row key={item.id} onClick={() => handleTableRowClick(item)}>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.drop_id ? item.drop_id : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        <div className='wrapSpacing nftName'>
                          <DropsTableWineName data={item} />
                        </div>
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.start_drop_event
                          ? formatAMPM(new Date(item?.start_drop_event))
                          : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.duration ? `${item.duration} Hrs` : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.status ? item.status : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.no_of_nft ? item.no_of_nft : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.sold_nfts ? item.sold_nfts : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.bottle_data.creator_meta.winery_name
                          ? item.bottle_data.creator_meta.winery_name
                          : '---'}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.price ? item.price : '---'}
                      </Table.Cell>

                      <Table.Cell colSpan='2' className='lastLink'>
                        {parseInt(item?.is_exclusive_list) === 1 ? <>yes</> : <>No</>}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        <DropdownEvents
                          icon='ellipsis vertical'
                          postion='left md-right'
                          selectedTab={selectedTab}
                          rowObject={item}
                          getAllNft={getAllNft}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ),
              )}
            {selectedTab === 'CURRENT' &&
              nftList.map(
                (item) =>
                  (item.status === 'LIVE' ||
                    item.status === 'APPROVED' ||
                    item.status === 'EMPTY_APPROVED' ||
                    item.status === 'MINTED') && (
                    <Table.Row key={item.id} onClick={() => handleTableRowClick(item)}>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.drop_id ? item.drop_id : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        <div className='wrapSpacing  nftName'>
                          <DropsTableWineName data={item} />
                        </div>
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.start_drop_event
                          ? formatAMPM(new Date(item?.start_drop_event))
                          : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.duration ? `${item.duration} Hrs` : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.status ? item.status : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.no_of_nft ? item.no_of_nft : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.sold_nfts ? item.sold_nfts : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.bottle_data.creator_meta.winery_name
                          ? item.bottle_data.creator_meta.winery_name
                          : '---'}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.price ? item.price : '---'}
                      </Table.Cell>

                      <Table.Cell colSpan='2' className='lastLink'>
                        {parseInt(item?.is_exclusive_list) === 1 ? <>yes</> : <>No</>}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        <DropdownEvents
                          icon='ellipsis vertical'
                          postion='left md-right'
                          selectedTab={selectedTab}
                          rowObject={item}
                          getAllNft={getAllNft}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ),
              )}

            {selectedTab === 'COMPLETED' &&
              nftList.map(
                (item) =>
                  (item.status === 'ENDED' || item.status === 'SOLD_OUT') && (
                    <Table.Row key={item.id} onClick={() => handleTableRowClick(item)}>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.drop_id ? item.drop_id : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        <div className='wrapSpacing nftName'>
                          <DropsTableWineName data={item} />
                        </div>
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.start_drop_event
                          ? formatAMPM(new Date(item?.start_drop_event))
                          : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.duration ? `${item.duration} Hrs` : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.status ? item.status : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.no_of_nft ? item.no_of_nft : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='4' textAlign='left'>
                        {item.sold_nfts ? item.sold_nfts : '---'}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='2' textAlign='left'>
                        {item.bottle_data.creator_meta.winery_name
                          ? item.bottle_data.creator_meta.winery_name
                          : '---'}
                      </Table.Cell>

                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        {item.price ? item.price : '---'}
                      </Table.Cell>

                      <Table.Cell colSpan='2' className='lastLink'>
                        {parseInt(item?.is_exclusive_list) === 1 ? <>yes</> : <>No</>}
                      </Table.Cell>
                      <Table.Cell collapsing colSpan='1' textAlign='left'>
                        <DropdownEvents
                          icon='ellipsis vertical'
                          postion='left md-right'
                          selectedTab={selectedTab}
                          rowObject={item}
                          getAllNft={getAllNft}
                        />
                      </Table.Cell>
                    </Table.Row>
                  ),
              )}
            {totalNft === 0 && (
              <Table.Row>
                <Table.Cell collapsing colSpan='20' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={optionLimit}
            onChange={(e, data) => {
              setLimit(data.value)
            }}
            className='autowidth SortingIcon'
          />
        </div>

        {totalNft > limit && (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            defaultActivePage={1}
            onPageChange={handlePageClick}
            totalPages={Math.ceil(parseInt(totalNft) / limit)}
          />
        )}
      </div>
    </div>
  )
}

export default DraftsTab
