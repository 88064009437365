import { fetch } from './Fetch'
import Environments from '../_constants/Environment'

const { API_HOST } = Environments

const getAllELAConsent = (data, headers) => {
  return fetch('get', `${API_HOST}/admin/api/v1/ela-consent/all`, {}, headers, data)
}

export const ELAConsentService = {
  getAllELAConsent,
}
