import React, { useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
  array as yupArray,
} from 'yup'
import { Formik, Form } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy, uniq, uniqBy } from 'lodash'
import { Checkbox, Col, Row, Select } from 'antd'
import cn from 'classnames'

import InputField from 'components/common/InputField/InputField'
import DropdownField from 'components/common/DropdownField/DropdownField'

import { ButtonCustom } from 'components/button/ButtonCustom'
import { getAllNftList } from 'redux/_actions/nft.action'
import useDebounce from 'hooks/useDebounce'
import DropsTableWineName from 'pages/private/Drop_Events/dropsTableWineName/DropsTableWineName'

import './DropCollectionForm.scss'
import { NftService } from 'Services/nft.service'
import { AUTOMATED_TYPES, AUTOMATED_TYPES_OPTIONS } from '../../const'
import { BottlesService } from 'Services/bottles.service'

const DropCollectionForm = ({ data, onSubmit }) => {
  const [dropIds, setDropIds] = useState(data?.drop_ids || [])
  // const [limit, setLimit] = useState(50)
  // const [offset, setOffset] = useState(1)
  const [search, setSearch] = useState('')
  const debounceDropSearch = useDebounce(search, 500)
  const dispatch = useDispatch()

  const allDropsRef = useRef({}) // to store all drops in the way `{ 251001: {  drop_details } }`
  const nftList = useSelector((state) => state?.nft?.nftList)

  const [regions, setRegions] = useState([])
  const [regionNames, setRegionNames] = useState(data?.region_names || [])
  const [searchRegions, setSearchRegions] = useState('')

  useEffect(() => {
    if (nftList?.length) {
      allDropsRef.current = { ...allDropsRef.current, ...keyBy(nftList, 'drop_id') }
    }
  }, [nftList])

  useEffect(() => {
    const loadCollectionDrops = async () => {
      const dataFields = {
        limit: 50,
        offset: 1,
        searchKey: '',
        status: '',
        order: 'DESC',
        event_status: 'DRAFT',
        dropStatus: 'DRAFT,EMPTY_DRAFT,APPROVED,LIVE,MINTED,EMPTY_APPROVED,ENDED,SOLD_OUT',
        drop_ids: data?.drop_ids?.join(','),
      }
      try {
        const res = await NftService.getAllNftList(dataFields, {
          Authorization: localStorage.getItem('authorizations'),
        })
        const collectionDrops = res?.data?.data
        if (collectionDrops?.length) {
          allDropsRef.current = { ...allDropsRef.current, ...keyBy(collectionDrops, 'drop_id') }
          setDropIds((ids) => [...ids]) // need to rerender after getting collection drops
        }
      } catch (error) {}
    }
    if (data?.drop_ids) {
      loadCollectionDrops()
    }
  }, [])

  useEffect(() => {
    const loadActiveRegions = async () => {
      try {
        const res = await BottlesService.getAllActiveRegions({
          Authorization: localStorage.getItem('authorizations'),
        })
        const regionsRes = (res?.data?.data || []).map((el) => ({
          ...el,
          label: el.region_name,
          value: el.region_name,
        }))
        if (regionsRes?.length) {
          setRegions(regionsRes)
        }
      } catch (error) {}
    }
    loadActiveRegions()
  }, [])

  useEffect(() => {
    const getAllDrops = () => {
      let dataFields = {
        limit: 50,
        offset: 1,
        searchKey: debounceDropSearch,
        status: '',
        order: 'DESC',
        event_status: 'DRAFT',
        dropStatus: 'DRAFT,EMPTY_DRAFT,APPROVED,LIVE,MINTED,EMPTY_APPROVED,ENDED,SOLD_OUT',
      }

      dispatch(getAllNftList(dataFields))
    }
    getAllDrops()
  }, [debounceDropSearch, dispatch])

  const drops = useMemo(() => {
    const chosenDrops = dropIds.map((id) => allDropsRef.current[id]).filter((el) => el)
    return uniqBy([...nftList, ...chosenDrops], 'drop_id').map((el) => ({
      value: el.drop_id,
      label: DropsTableWineName({ data: el }),
    }))
  }, [nftList, dropIds])

  const regionsFiltered = useMemo(() => {
    if (!searchRegions) return regions

    return regions.filter((el) =>
      el.region_name.toLowerCase().includes(searchRegions.toLowerCase()),
    )
  }, [regions, searchRegions])

  const initialValues = {
    name: data?.name || '',
    drop_ids: data?.drop_ids || [],
    is_enabled: !!data?.is_enabled,
    order: data?.order || '',
    automated_type: data?.automated_type || '',
    region_names: data?.region_names || [],
    url: data?.url || '',
  }

  const validationSchema = {
    name: yupString().required('is required').max(300, 'Must less then 300 digits'),
    drop_ids: yupArray().of(yupNumber()),
    order: yupNumber().optional().min(1, '1 is minimum'),
    automated_type: yupString(),
    region_names: yupArray().of(yupString()),
  }
  if (data) {
    validationSchema.order = yupNumber().min(1, '1 is minimum').required('Required')
  }

  const onSelectChange =
    (setFieldValue, fieldName = 'drop_ids', stateFn = setDropIds) =>
    (values) => {
      stateFn((items) => {
        const chosenItems = uniq([...items, ...values])
        setFieldValue(fieldName, chosenItems)
        return chosenItems
      })
    }

  const onDeselect =
    (setFieldValue, fieldName = 'drop_ids', stateFn = setDropIds) =>
    (value) => {
      setTimeout(() => {
        stateFn((items) => {
          const chosenItems = items.filter((el) => el !== value)
          setFieldValue(fieldName, chosenItems.length ? chosenItems : '')
          return chosenItems
        })
      }, 0)
    }

  const onFormSubmit = (values) => {
    onSubmit({
      ...values,
      automated_type: values.automated_type || null,
    })
  }
  // console.log('drops', drops)
  const getDropCollectionUrl = (url) =>
    `${process.env.REACT_APP_FRONTEND_DOMAIN}/marketplace?dropCollection=${url}`
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={yupObject(validationSchema)}
      onSubmit={onFormSubmit}
      enableReinitialize
    >
      {({ errors, values, isValid, dirty, touched, handleChange, setFieldValue, setTouched }) => (
        <Form>
          <InputField
            name='name'
            label='Name'
            placeholder='E.g. Napa Valley'
            id='name'
            onChange={handleChange}
            value={values.name}
            formik={{
              errors,
            }}
            maxLength={255}
            isRequired
          />
          <InputField
            name='url'
            label='URL'
            placeholder='Only like this: top-sellers  --- no empty spaces, no special characters, no numbers, no capital letters'
            id='url'
            onChange={handleChange}
            value={values.url}
            formik={{
              errors,
            }}
            maxLength={255}
          />
          <div className='dropCollectionUrlWrapper'>
            The link is:{' '}
            <a href={getDropCollectionUrl(values.url)} target='_blank' rel='noreferrer'>
              {getDropCollectionUrl(values.url)}
            </a>
          </div>

          <DropdownField
            label='Automated type (if chosen, drop ids are skipped in the FE)'
            placeholder=''
            name='automated_type'
            value={values.automated_type}
            onChange={handleChange}
            options={AUTOMATED_TYPES_OPTIONS}
            error={errors?.automated_type}
          />
          <div
            className={cn('inputField__wrap', {
              hidden: values.automated_type !== AUTOMATED_TYPES.REGION_SPECIFIC_WINES,
            })}
          >
            <label className='inputField__label'>
              Choose regions that belong to the collection
            </label>
            <Select
              className='dropCollectionDropIds'
              showSearch
              mode='multiple'
              style={{ width: '100%' }}
              value={regionNames}
              placeholder='Search by Region Name'
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(ev) => setSearchRegions(ev)}
              onChange={onSelectChange(setFieldValue, 'region_names', setRegionNames)}
              notFoundContent={null}
              options={regionsFiltered}
              optionLabelProp='label'
              defaultValue={['']}
              onDeselect={onDeselect(setFieldValue, 'region_names', setRegionNames)}
              onFocus={() => setTouched({ region_names: true })}
            />
            {touched.region_names && errors.region_names && (
              <div className='form__field-error'>{errors.region_names}</div>
            )}
          </div>

          <div className='inputField__wrap'>
            <label className='inputField__label'>Choose drops that belong to the collection</label>
            <Select
              className='dropCollectionDropIds'
              showSearch
              mode='multiple'
              style={{ width: '100%' }}
              value={dropIds}
              placeholder='Search by Drop Name or Drop Id'
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(ev) => setSearch(ev)}
              onChange={onSelectChange(setFieldValue)}
              notFoundContent={null}
              options={drops}
              optionLabelProp='label'
              defaultValue={['']}
              onDeselect={onDeselect(setFieldValue)}
              onFocus={() => setTouched({ drop_ids: true })}
            />
            {touched.drop_ids && errors.drop_ids && (
              <div className='form__field-error'>{errors.drop_ids}</div>
            )}
          </div>

          <InputField
            name='order'
            label='Order'
            placeholder='E.g. 10'
            id='order'
            onChange={handleChange}
            value={values.order}
            formik={{ errors }}
            type='number'
            min='1'
          />

          <Row align='middle' justify='start' className='perameter_Inputs'>
            <label className='inputField__label'>
              <Checkbox
                name='is_enabled'
                label='Is Visible'
                checked={values.is_enabled}
                onChange={handleChange}
                className='inputField__checkbox'
              />
              Is Visible
            </label>
          </Row>

          <Row justify='end'>
            <Col>
              <ButtonCustom
                style={{ margin: 0 }}
                type='submit'
                title='Save'
                customClass='saveBtn'
                disabled={!(dirty && isValid)}
              />
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

DropCollectionForm.defaultProps = {
  data: null,
  errors: null,
}
DropCollectionForm.propTypes = {
  data: PropTypes.shape({}),
  errors: PropTypes.shape({}),
}

export default DropCollectionForm
