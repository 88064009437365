import { startLoading, stopLoading } from './loading.action'
import { toast } from '../../components/Toast/Toast'
import { BottlesService } from '../../Services/bottles.service'
/** seting action types */
export const actionTypes = {
  SAVE_ALL_BOTTLES: 'SAVE_ALL_BOTTLES',
}

export function saveMyAllBottles(data) {
  return {
    type: actionTypes.SAVE_ALL_BOTTLES,
    payload: data,
  }
}

export function getAllBottleList(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.getAllBottleList(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(saveMyAllBottles(res.data))
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getBottleDetail(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.getBottleDetail(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getBottleDropDownSpecifications() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.getAllBottleDropDownSpecifications({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
export function addBottles(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.addBottles(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function updateBottle(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.updateBottle(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function getAllWineryListForDropDown() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.getAllWineryListForDropDown({
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function deleteBottle(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.deleteBottle(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function publishUnpublishBottle(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.publishUnpublishBottle(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          toast.success(res.data?.message)
          dispatch(stopLoading())
          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function bulkCreateBottles(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.bulkCreateBottles(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function bulkUpdateBottles(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.bulkUpdateBottles(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function bulkUploadBottleImages(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.bulkUploadBottleImages(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}

export function bulkUploadBottleImagesNames(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      dispatch(startLoading())
      BottlesService.bulkUploadBottleImagesNames(data, {
        Authorization: localStorage.getItem('authorizations'),
      })
        .then((res) => {
          dispatch(stopLoading())

          resolve(res)
        })
        .catch((ex) => {
          dispatch(stopLoading())
          reject(ex)
        })
    })
}
