import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Popup } from 'semantic-ui-react'

import '../../../WinemakerDetails/WinemakerDetails.scss'
import DropdownMenu from '../dropdownMenu/DropdownMenu'
import { getAllDropCollections } from 'redux/_actions/dropCollection.action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown, faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { ButtonCustom } from 'components/button/ButtonCustom'
import updateOrder from '../../utils/updateOrder'

const Collections = () => {
  const { entries } = useSelector((state) => state?.dropCollection)
  const dispatch = useDispatch()

  const [menuOffset, setMenuOffset] = useState(null)

  const onMenuClick = (e) => {
    const element = e.target
    setMenuOffset(element.offsetLeft)
  }

  const onChangePosition =
    (item, i = 0) =>
    async (e) => {
      e.preventDefault()
      e.stopPropagation()
      await updateOrder(item, { ...item, order: i }, entries, true)
      dispatch(getAllDropCollections())
      return false
    }
  const getDropCollectionUrl = (url) => {
    if (!url) return '---'
    return `${process.env.REACT_APP_FRONTEND_DOMAIN}/marketplace?dropCollection=${url}`
  }
  return (
    <>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='2'>Id</Table.HeaderCell>
              <Table.HeaderCell colSpan='4'>Name</Table.HeaderCell>
              <Table.HeaderCell colSpan='5'>URL</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Active</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Order</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entries?.map((item, i) => (
              <Popup
                key={item.id}
                className='labelTableModal'
                offset={menuOffset}
                on={['click']}
                position='bottom left'
                trigger={
                  <Table.Row onClick={onMenuClick}>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.id || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='4' textAlign='left'>
                      {item.name || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='5' textAlign='left'>
                      {item.url ? (
                        <a
                          className='dropCollectionUrl'
                          href={getDropCollectionUrl(item.url)}
                          target='_blank'
                          rel='noreferrer'
                        >
                          Click Link <FontAwesomeIcon icon={faExternalLink} />
                        </a>
                      ) : (
                        '---'
                      )}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.is_enabled ? 'Yes' : 'No'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      {item.order || '---'}
                    </Table.Cell>
                    <Table.Cell collapsing colSpan='2' textAlign='left'>
                      <div>
                        <ButtonCustom
                          onClick={onChangePosition(item, i)}
                          customClass='btnArrow'
                          leftIcon={<FontAwesomeIcon icon={faAngleUp} />}
                          disabled={i === 0}
                        />
                      </div>
                      <div>
                        <ButtonCustom
                          onClick={onChangePosition(item, i + 2)}
                          customClass='btnArrow'
                          leftIcon={<FontAwesomeIcon icon={faAngleDown} />}
                          disabled={i === entries.length - 1}
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                }
              >
                <DropdownMenu data={item} getAllEntries={() => dispatch(getAllDropCollections())} />
              </Popup>
            ))}
            {!entries?.length && (
              <Table.Row>
                <Table.Cell collapsing colSpan='24' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    </>
  )
}

Collections.defaultProps = {
  limit: 100,
}
Collections.propTypes = {
  limit: PropTypes.number,
}

export default Collections
