import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Tabs } from 'antd'

import MainLayout from '../../../../components/HOC/MainLayout/MainLayout'
import useDebounce from '../../../../hooks/useDebounce'

import Filters from './filters/Filters'
import Consents from './consents/Consents'
import { getAllELAConsents } from 'redux/_actions/elaConsents.action'

const ELAConsents = () => {
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState('')
  const [order, setOrder] = useState('')
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(1)
  const debounceWinerySearch = useDebounce(search, 500)

  const dispatch = useDispatch()

  const getAllEntries = () => {
    const dataFields = {
      limit,
      offset,
      searchKey: debounceWinerySearch,
      status,
      order,
    }
    dispatch(getAllELAConsents(dataFields))
  }
  useEffect(() => {
    const dataFields = {
      limit,
      offset,
      searchKey: debounceWinerySearch,
      status,
      order,
    }
    console.log('dataFields', dataFields)
    dispatch(getAllELAConsents(dataFields))
  }, [offset, limit, order, status, debounceWinerySearch, dispatch])

  return (
    <MainLayout>
      <h3 className='common-sub-heading mb30'>ELA Consents</h3>
      <Tabs
        className='exclusive__tabs'
        defaultActiveKey='1'
        items={[
          {
            tab: 'ELA Consents',
            key: 'ELA Consents',
            children: (
              <div className='detail-tab'>
                <Filters
                  search={search}
                  setSearch={setSearch}
                  status={status}
                  setStatus={setStatus}
                  order={order}
                  setOrder={setOrder}
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffset={setOffset}
                />
                <Consents
                  limit={limit}
                  setLimit={setLimit}
                  offset={offset}
                  setOffset={setOffset}
                  getAllEntries={getAllEntries}
                />
              </div>
            ),
          },
        ]}
      />
    </MainLayout>
  )
}

export default ELAConsents
