import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Dropdown, Table, Pagination } from 'semantic-ui-react'
import moment from 'moment'

import '../../../WinemakerDetails/WinemakerDetails.scss'

const optionLimit = [
  { key: '1', text: 10, value: '10' },
  { key: '2', text: 15, value: '15' },
  { key: '3', text: 20, value: '20' },
]

const Consents = ({ limit, setLimit, offset, setOffset, getAllEntries }) => {
  const { entries, total: totalConsents } = useSelector((state) => state?.elaConsent)

  const handlePageClick = (e, pageInfo) => {
    let offset = pageInfo.activePage
    setOffset(offset)
  }

  if (!entries?.length) return null

  const getUserName = (user) => `${user?.first_name?.trim()} ${user?.last_name?.trim()}`.trim()
  return (
    <>
      <div className='Table_Editing'>
        <Table className='custom_table'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan='4'>User</Table.HeaderCell>
              <Table.HeaderCell colSpan='4'>User Email</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Nft Id</Table.HeaderCell>
              <Table.HeaderCell colSpan='1'>Drop Id</Table.HeaderCell>
              <Table.HeaderCell colSpan='5'>Drop Name</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Date</Table.HeaderCell>
              <Table.HeaderCell colSpan='2'>Purchase Id</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {entries?.map((item) => (
              <Table.Row key={item.id}>
                <Table.Cell collapsing colSpan='4' textAlign='left'>
                  {getUserName(item.user) || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='4' textAlign='left'>
                  {item.user?.email || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {item?.nft_token_id || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='1' textAlign='left'>
                  {item.nft?.nft_details?.drop_id || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='5' textAlign='left'>
                  {item.nft?.nft_details?.nft_name || '---'}
                </Table.Cell>
                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {item.updatedAt ? moment(item.updatedAt).format('DD/MM/YYYY') : '---'}
                </Table.Cell>

                <Table.Cell collapsing colSpan='2' textAlign='left'>
                  {item.purchase_id || '---'}
                </Table.Cell>
              </Table.Row>
            ))}
            {totalConsents === 0 && (
              <Table.Row>
                <Table.Cell collapsing colSpan='20' textAlign='center'>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bolder',
                      fontSize: '15px',
                    }}
                  >
                    No Record Found.
                  </div>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
      <div className='table_pagi new'>
        <div className='sorting'>
          <span>Show On Page &nbsp;</span>
          <Dropdown
            value={limit.toString()}
            selection
            options={optionLimit}
            onChange={(e, data) => {
              setLimit(data.value)
            }}
            className='autowidth SortingIcon'
          />
        </div>

        {totalConsents > limit && (
          <Pagination
            className='ListPagi'
            firstItem={false}
            lastItem={false}
            boundaryRange={1}
            siblingRange={0}
            defaultActivePage={1}
            onPageChange={handlePageClick}
            totalPages={Math.ceil(parseInt(totalConsents) / limit)}
          />
        )}
      </div>
    </>
  )
}

Consents.defaultProps = {
  limit: 10,
}
Consents.propTypes = {
  limit: PropTypes.number,
  setLimit: PropTypes.func.isRequired,
  getAllDiscounts: PropTypes.func.isRequired,
}

export default Consents
