import { actionTypes } from '../_actions/revenueFiles.action'

const initialState = {
  entries: [],
}

const revenueFiles = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case actionTypes.SAVE_REVENUE_FILES:
      return { ...state, entries: payload }

    default:
      return state
  }
}

export default revenueFiles
